<template>
  <div class="cartesian-table-container">
    <!-- Input Form Section -->
    <v-card class="input-card mb-6" elevation="2">
      <v-card-text>
        <div class="d-flex align-center mb-4">
          <h3 class="text-h6 grey--text text--darken-2 mb-0">
            Parámetros de Análisis
          </h3>
          <v-spacer></v-spacer>
          <v-btn
            :color="editMode ? 'error' : 'primary'"
            small
            @click="editMode = !editMode"
            class="mr-2"
          >
            <v-icon left>{{ editMode ? "mdi-close" : "mdi-pencil" }}</v-icon>
            {{ editMode ? "Cancelar" : "Editar" }}
          </v-btn>
          <v-btn
            small
            :disabled="!notOriginValues"
            @click="resetValues"
            color="info"
          >
            <v-icon left>mdi-restart</v-icon>
            Resetear
          </v-btn>
        </div>

        <div class="d-flex">
          <v-text-field
            v-model.number="localDynamicPrice"
            label="Precio"
            type="number"
            :hint="isPriceModify"
            persistent-hint
            :readonly="!editMode"
            :filled="!editMode"
            class="mr-4"
            outlined
            dense
            :class="{ 'modified-input': activityPrice != localDynamicPrice }"
            required
          ></v-text-field>

          <v-text-field
            v-model.number="localDynamicYield"
            label="Rinde dinámico"
            type="number"
            :hint="isDynamicYieldModify"
            persistent-hint
            :readonly="!editMode"
            :filled="!editMode"
            outlined
            dense
            :class="{ 'modified-input': dynamicYield != localDynamicYield }"
            required
          ></v-text-field>
        </div>
      </v-card-text>
    </v-card>

    <!-- Table Section -->
    <v-card elevation="2">
      <v-card-text>
        <table class="cartesian-table">
          <tr>
            <th class="header-cell diagonal-header">
              <div class="diagonal-content">
                <span class="yield-label">Rinde</span>
                <span class="price-label">Precio</span>
              </div>
            </th>
            <th
              v-for="value in axisX"
              :key="`${value}id1`"
              class="header-cell text-center"
            >
              {{ value }}
            </th>
          </tr>
          <tr v-for="valueY in axisY" :key="`${valueY}id2`" class="table-row">
            <td class="side-header">{{ valueY }}</td>
            <td
              v-for="valueX in axisX"
              :key="`${valueX}id3`"
              class="value-cell"
              :class="getCellClass(valueY, valueX)"
            >
              {{ setCellContentValue(valueY, valueX) }}
            </td>
          </tr>
        </table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { parseToLocaleString, roundToX } from "../../managrx/utils";
export default {
  name: "AbstractCartesianProductTable",
  props: {
    activityPrice: Number,
    dynamicYield: Number,
    saleTax: Number,
    saleComission: Number,
    saleHandOver: Number,
    totalOtherTonCostByTon: Number,
    freightPrice: Number,
    technicalDirection: Number,
    percentHc: Number,
    maxHc: Number,
    minHc: Number,
    // Rent Bonus ONLY if delivers kg, if not its already in the nonautofinanciablecosts
    rentBonusPercent: Number,
    rentBonusYieldThreshold: Number,
    nonAutofinanciableCosts: Number,
    fixedSharecroppingPercent: Number,
    varaibleSharecroppingArray: Array,
    grainQualityFactor: Number,
  },
  data() {
    return {
      localDynamicPrice: 0,
      localDynamicYield: 0,
      editMode: false,
      cartesianProductAxis: [0.75, 0.9, 1, 1.25],
    };
  },
  beforeMount() {
    this.resetValues();
  },
  mounted() {
    console.log(
      "AbstractCartesianProductTable mounted with props:",
      this.$props
    );
  },
  methods: {
    setCellContentValue(dynamicYieldPercent, activityPricePercent) {
      // Calculate base values
      let cellDynamicYield = this.localDynamicYield * dynamicYieldPercent;
      let baseActivityPrice = this.localDynamicPrice * activityPricePercent;
      let cellActivityPrice =
        baseActivityPrice * (this.grainQualityFactor || 1);
      let harvestCost = this.getHarvestCost(
        cellDynamicYield,
        baseActivityPrice
      );
      let tons = cellDynamicYield / 10;
      let ownTonsProportion = this.getOwnTonsProportion(cellDynamicYield);

      let salesExpenses =
        (cellDynamicYield / 10) *
        ownTonsProportion *
        (cellActivityPrice * (this.saleTax + this.saleComission) +
          this.saleHandOver);
      let freightCost =
        (cellDynamicYield / 10) * ownTonsProportion * this.freightPrice;
      let priceForTechDir =
        baseActivityPrice -
        (cellActivityPrice * (this.saleTax + this.saleComission) +
          this.saleHandOver);
      // let netPrice = priceForTechDir - this.freightPrice;

      let totalIncome = ownTonsProportion * tons * cellActivityPrice;

      let techDirBase = (cellDynamicYield / 10) * priceForTechDir;
      let technicalDirectionCost = techDirBase * this.technicalDirection;
      let tonBasedCosts = tons * this.totalOtherTonCostByTon;
      let profitsByHa =
        totalIncome -
        salesExpenses -
        freightCost -
        technicalDirectionCost -
        harvestCost -
        tonBasedCosts -
        this.nonAutofinanciableCosts;

      // Only log for base case (1×1)
      if (dynamicYieldPercent === 1 && activityPricePercent === 1) {
        console.group("Financial Calculation Breakdown (Base Case)");

        console.log("1. Initial Parameters:");
        console.log(`   Dynamic Yield: ${this.localDynamicYield} qq/ha`);
        console.log(`   Price: ${this.localDynamicPrice} per ton`);
        console.log(`   Grain Quality Factor: ${this.grainQualityFactor || 1}`);

        console.log("\n2. Production Metrics:");
        console.log(`   Yield: ${cellDynamicYield} qq/ha`);
        console.log(`   Tons: ${tons} ton/ha`);
        console.log(
          `   Own Tons Proportion: ${(ownTonsProportion * 100).toFixed(2)}%`
        );

        console.log("\n3. Price Calculations:");
        console.log(`   Base Price: ${baseActivityPrice}`);
        console.log(`   Adjusted Price (with quality): ${cellActivityPrice}`);
        console.log(`   Price for Tech Direction: ${priceForTechDir}`);

        console.log("\n4. Income:");
        console.log(`   Total Income: ${totalIncome}`);
        console.log(
          `   Formula: ${tons} tons × ${ownTonsProportion} (own proportion) × ${cellActivityPrice} (price)`
        );

        console.log("\n5. Costs Breakdown:");
        console.log(`   a. Harvest Cost: ${harvestCost}`);
        console.log(`   b. Sales Expenses: ${salesExpenses}`);
        console.log(`      - Sale Tax: ${this.saleTax * 100}%`);
        console.log(`      - Sale Commission: ${this.saleComission * 100}%`);
        console.log(`      - Sale Handover: ${this.saleHandOver}`);
        console.log(`   c. Freight Cost: ${freightCost}`);
        console.log(`   d. Technical Direction: ${technicalDirectionCost}`);
        console.log(`   e. Ton-based Costs: ${tonBasedCosts}`);
        console.log(
          `   f. Non-autofinanciable Costs: ${this.nonAutofinanciableCosts}`
        );

        console.log("\n6. Final Result:");
        console.log(`   Total Profit per Ha: ${profitsByHa}`);
        console.log(
          `   Formatted Profit: ${parseToLocaleString(
            roundToX(profitsByHa, 0)
          )}`
        );

        console.groupEnd();
      }

      return parseToLocaleString(roundToX(profitsByHa, 0));
    },
    getOwnTonsProportion(dynamicYield) {
      return (
        1 -
        this.fixedSharecroppingPercent -
        this.getApplicableVaraibleSharecropping(dynamicYield) -
        this.getMaterialziedRentBonus(dynamicYield)
      );
    },
    getMaterialziedRentBonus(dynamicYield) {
      return (
        ((dynamicYield - (this?.rentBonusYieldThreshold || 0)) / dynamicYield) *
        (this?.rentBonusPercent || 0)
      );
    },
    getApplicableVaraibleSharecropping(dynamicYield) {
      for (const vsc of this.varaibleSharecroppingArray) {
        if (dynamicYield >= vsc.floor && dynamicYield < vsc.ceiling) {
          return vsc.percent;
        }
      }
      return 0;
    },
    getHarvestCost(dynamicYield, activityPrice) {
      let totalProductionValue = (dynamicYield / 10) * activityPrice;
      let percentHCost = totalProductionValue * this.percentHc;

      // Apply min/max bounds
      if (percentHCost > this.maxHc) {
        return this.maxHc;
      }
      if (percentHCost < this.minHc) {
        return this.minHc;
      }
      return roundToX(percentHCost, 2);
    },

    resetValues() {
      this.localDynamicPrice = this.activityPrice;
      this.localDynamicYield = this.dynamicYield;
    },

    getCellClass(yValue, xValue) {
      const value = Number(
        this.setCellContentValue(yValue, xValue)
          .replace(/\./g, "")
          .replace(",", ".")
      );
      return {
        "positive-value": value > 0,
        "negative-value": value < 0,
        "neutral-value": value === 0,
        "center-value": yValue === 1 && xValue === 1,
      };
    },
  },

  computed: {
    axisX() {
      return [...this.cartesianProductAxis];
    },
    axisY() {
      return [...this.cartesianProductAxis];
    },
    notOriginValues() {
      if (
        this.localDynamicPrice != this.activityPrice ||
        this.localDynamicYield != this.dynamicYield
      ) {
        return true;
      }
      return false;
    },
    isDynamicYieldModify() {
      return this.dynamicYield != this.localDynamicYield
        ? "*El valor fue modificado"
        : "";
    },
    isPriceModify() {
      return this.activityPrice != this.localDynamicPrice
        ? "*El valor fue modificado"
        : "";
    },
  },
  components: {},
};
</script>

<style scoped>
.cartesian-table-container {
  padding: 16px;
}

.input-card {
  background-color: #f8f9fa !important;
}

.modified-input {
  border-left: 4px solid var(--v-warning-base) !important;
}

.cartesian-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 2px;
  margin-top: 8px;
}

.header-cell {
  background-color: #f5f5f5;
  padding: 12px;
  font-weight: 500;
  color: #424242;
}

.diagonal-header {
  position: relative;
  width: 120px;
  height: 120px;
}

.diagonal-content {
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top right,
    #f5f5f5 calc(50% - 1px),
    #bdbdbd 50%,
    #f5f5f5 calc(50% + 1px)
  );
}

.yield-label {
  position: absolute;
  bottom: 8px;
  left: 8px;
}

.price-label {
  position: absolute;
  top: 8px;
  right: 8px;
}

.side-header {
  background-color: #f5f5f5;
  padding: 12px;
  font-weight: 500;
  color: #424242;
  text-align: center;
}

.value-cell {
  padding: 16px;
  text-align: center;
  transition: all 0.3s ease;
  border-radius: 4px;
}

.positive-value {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.negative-value {
  background-color: #ffebee;
  color: #c62828;
}

.neutral-value {
  background-color: #f5f5f5;
  color: #616161;
}

.center-value {
  border: 2px solid #1976d2;
  font-weight: bold;
}

.table-row:hover {
  .value-cell {
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}

.value-cell:hover {
  transform: scale(1.05) !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15) !important;
  z-index: 1;
}
</style>
