<template>
  <AbstractFreightCostInput
    :fixedShareCroppingValue="fixedShareCroppingValue"
    :variableShareCroppingValue="variableShareCroppingValue"
    :isFixedSharecroppingHandedOver="
      singleDraftActivitySlice.isFixedSharecroppingHandedOver
    "
    :isVariableSharecroppingHandedOver="
      singleDraftActivitySlice.isVariableSharecroppingHandedOver
    "
    :assignedHectares="singleDraftActivitySlice.assignedHectares"
    :activitySliceId="singleDraftActivitySlice.activitySliceId"
    :dynamicYield="singleDraftActivitySlice.summary.dynamicYield"
    :freightCostTn="singleDraftActivitySlice.freightCostTn"
    :rentBonusYieldThreshold="rentBonusYieldThreshold"
    :rentBonusPercent="rentBonusPercent"
    :rentBonusIsUsdHa="rentBonusIsUsdHa"
    :isDraft="true"
    @saveClick="saveClick"
  />
</template>

<script>
import AbstractFreightCostInput from "../Abstract/AbstractFreightCostInput.vue";
import { mapGetters } from "vuex";
import { putRequest } from "../../managrx/serverCall";
export default {
  name: "freightCostInput",
  props: {
    assignedHectares: Number,
    activitySliceId: Number,
    title: String,
    row: Object,
  },
  components: {
    AbstractFreightCostInput,
  },
  methods: {
    async saveClick(payload) {
      let response = await putRequest(
        `/draft_activity_slice/${this.singleDraftActivitySlice.id}`,
        payload
      );
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.$emit("putResponseSuccess", {});
      }
      this.editMode = false;
    },
  },
  computed: {
    ...mapGetters(["singleDraftActivitySlice"]),
    fixedShareCroppingValue() {
      return this.singleDraftActivitySlice?.isFixedSharecroppingHandedOver
        ? this.singleDraftActivitySlice?.fixedSharecroppingPercent
        : 0;
    },
    variableShareCroppingValue() {
      return this.singleDraftActivitySlice?.isVariableSharecroppingHandedOver
        ? this.singleDraftActivitySlice?.summary
            ?.variableSharecroppingTotalPercent
        : 0;
    },
    rentBonusYieldThreshold() {
      return this.singleDraftActivitySlice?.rentBonus?.yieldThreshold ?? 0;
    },
    rentBonusPercent() {
      return this.singleDraftActivitySlice?.rentBonus?.rentBonusPercent ?? 0;
    },
    rentBonusIsUsdHa() {
      return this.singleDraftActivitySlice?.rentBonus?.isUsdHa ?? 0;
    },
  },
};
</script>
