<template>
  <div class="pr-2 pl-2">
    <div class="d-flex flex-column" v-if="editMode">
      <div class="d-flex justify-space-around flex-lg-row flex-xl-row">
        <v-text-field
          label="Familia"
          outlined
          dense
          class="managrx-input mr-2"
          v-model="newRow.family"
          readonly
          filled
        ></v-text-field>
        <v-select
          ref="itemSelect"
          open-on-clear
          v-model="newRow.directSliceCostItemId"
          :items="selectableDraftCostItems"
          @change="directSliceCostItemSelectChange"
          item-text="name"
          item-value="id"
          label="Insumo"
          dense
          outlined
          class="managrx-input mr-2"
        ></v-select>
        <v-text-field
          label="Precio"
          @change="computeSubtotal"
          outlined
          dense
          type="number"
          class="managrx-input mr-2"
          v-model.number="newRow.unitPrice"
        ></v-text-field>

        <v-select
          v-if="!isSandGFamily"
          ref="periodSelect"
          v-model="newRow.costInputPeriodId"
          :items="sliceInputCostPeriods"
          item-text="name"
          item-value="id"
          label="Periodo"
          dense
          outlined
          class="managrx-input mr-2"
        ></v-select>

        <v-text-field
          v-if="!isSandGFamily"
          label="Hectáreas Asignadas"
          outlined
          dense
          class="managrx-input mr-2"
          v-model="assignedHectares"
          readonly
          filled
        ></v-text-field>

        <v-text-field
          v-if="!isSandGFamily"
          label="(0-100)% App"
          append-icon="mdi-percent-outline"
          @change="computeSubtotal"
          outlined
          dense
          type="number"
          class="managrx-input mr-2"
          v-model.number="newRow.percentApplicationAsInt"
        ></v-text-field>

        <v-text-field
          v-if="!isSandGFamily"
          label="Unidades x Hectáreas"
          @change="computeSubtotal"
          v-model.number="newRow.units"
          type="number"
          outlined
          dense
          class="managrx-input mr-2"
        ></v-text-field>

        <v-text-field
          v-if="!isSandGFamily"
          label="Unidad de Medida"
          outlined
          dense
          class="managrx-input mr-2"
          readonly
          filled
          v-model="newRow.unitMeasure"
        ></v-text-field>

        <v-text-field
          label="Subtotal"
          outlined
          dense
          class="managrx-input mr-2"
          readonly
          filled
          :value="newRow.subTotal"
        ></v-text-field>
      </div>

      <div class="d-flex">
        <v-text-field
          class="managrx-input mr-2"
          outlined
          dense
          label="Fecha de uso"
          v-model="newRow.dateUsed"
          type="date"
        ></v-text-field>

        <v-select
          v-if="!isSandGFamily"
          class="managrx-input mr-2"
          :items="[
            { value: 'Fixed Date', idx: 1 },
            { value: 'Policy Based', idx: 2 },
          ]"
          item-text="value"
          item-value="idx"
          label="Opción de pago"
          dense
          outlined
          v-model="paymentOption"
          :readonly="hasPaymentPolicy"
          :filled="hasPaymentPolicy"
        >
        </v-select>
        <v-text-field
          class="managrx-input mr-2"
          outlined
          dense
          label="Fecha de pago"
          v-model="datePaid"
          v-if="paymentOption === 1"
          type="date"
        ></v-text-field>

        <v-select
          v-if="paymentOption === 2 && !isSandGFamily"
          ref="paymentPolicySelect"
          v-model="newRow.paymentPolicyId"
          open-on-clear
          :items="financialPolicies"
          item-text="name"
          item-value="id"
          label="Política de pago"
          dense
          outlined
          class="managrx-input mr-2"
        ></v-select>

        <v-select
          v-if="!isSandGFamily"
          ref="interestSelect"
          v-model="newRow.interestRateId"
          open-on-clear
          :items="interestRates"
          item-text="name"
          item-value="id"
          label="Financiación"
          dense
          outlined
          class="managrx-input mr-2"
        ></v-select>

        <v-btn
          depressed
          color="primary white--text"
          class="mr-2"
          @click="saveClick"
        >
          <v-icon> mdi-floppy</v-icon>
        </v-btn>

        <v-btn
          depressed
          color="info white--text"
          class="mr-2"
          @click="backClick"
        >
          Atrás
        </v-btn>
      </div>

      <v-divider class="mb-5"> </v-divider>
    </div>
    <div class="d-flex" v-if="!editMode">
      <v-text-field
        label="Familia"
        outlined
        dense
        filled
        class="managrx-input mr-2"
        v-model="row.family"
        readonly
      ></v-text-field>

      <v-text-field
        label="Insumo"
        outlined
        dense
        filled
        class="managrx-input mr-2"
        v-model="row.directSliceCostItemName"
        @click="goToDirectSliceCostItem(row.directSliceCostItemId)"
        readonly
      ></v-text-field>

      <v-text-field
        label="Periodo"
        outlined
        dense
        class="managrx-input mr-2"
        v-model="row.periodType"
        readonly
        filled
        v-if="row.periodType"
      ></v-text-field>

      <v-text-field
        label="Dosis"
        outlined
        dense
        class="managrx-input mr-2"
        readonly
        :value="displayedDosisString"
        filled
      ></v-text-field>

      <v-text-field
        class="managrx-input mr-2"
        label="Fecha de uso"
        v-model="row.dateUsed"
        outlined
        dense
        readonly
        filled
      ></v-text-field>

      <v-text-field
        :value="row.datePaid"
        label="Fecha de pago"
        outlined
        dense
        class="managrx-input mr-2"
        readonly
        filled
        v-if="row.datePaid"
      ></v-text-field>

      <v-text-field
        :value="row.paymentPolicyName"
        label="Política de pago"
        outlined
        dense
        class="managrx-input mr-2"
        readonly
        filled
        v-if="row.paymentPolicyName"
      ></v-text-field>

      <v-btn
        :depressed="true"
        v-if="!deleteMode"
        color="warning white--text"
        class="mr-2"
        @click="editClick"
      >
        <v-icon> mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        v-if="deleteMode"
        :depressed="true"
        color="error"
        class="mr-2 white--text lighten-1"
        @click="confirmDeleteClick"
      >
        Confirmar borrado
      </v-btn>
      <v-btn
        :depressed="true"
        v-if="deleteMode"
        color="warning white--text"
        class="mr-2"
        @click="backClick"
      >
        Atrás
      </v-btn>
      <v-btn
        depressed
        color="error  white--text"
        class="mr-2"
        @click="deleteClick"
        v-if="!deleteMode"
      >
        <v-icon>mdi-trash-can</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.v-text-field {
  width: 300px;
}
.managrx-input {
  font-weight: 400 !important;
}
</style>

<script>
import useSharedSliceCosts from "@/mixins/useSharedSliceCosts";

import { validateRequestObject } from "@/managrx/services";
import { properties } from "../sharedProps/draftSliceCostItems";

import useFindables from "../../mixins/useFindables";
import { roundToX } from "../../managrx/utils";

export default {
  name: "AbstractDraftSliceCostInput",
  props: { ...properties, directSliceCostItems: Array },
  mixins: [useSharedSliceCosts, useFindables],
  methods: {
    goToDirectSliceCostItem(directSliceCostItemId) {
      let routeData = this.$router.resolve({
        name: "ListedCostItems",
        params: { id: directSliceCostItemId },
      });
      window.open(routeData.href, "_blank");
    },
    directSliceCostItemSelectChange(itemId) {
      let candidate = this.directSliceCostItems.find(
        (item) => item.id === itemId
      );
      this.newRow.unitMeasure = candidate.unitMeasure;
      this.newRow.family = candidate.costItemFamilyName;
    },
    computeSubtotal() {
      this.newRow.subTotal = roundToX(
        this.newRow.unitPrice *
          this.newRow.units *
          this.assignedHectares *
          this.newRow.percentApplication,
        2
      )?.toLocaleString("en-US", {
        timeZone: "America/Argentina/Buenos_Aires",
      });
    },
    deleteClick() {
      this.deleteMode = true;
    },
    async saveClick() {
      let payload = {
        percentApplication: this.percentAppAsDecimal,
        directSliceCostItemId: this.newRow.directSliceCostItemId,
        unitPrice: this.newRow.unitPrice,
        costInputPeriodId: this.newRow.costInputPeriodId,
        unitsPerHectare: this.newRow.units,
        dateUsed: new Date(this.newRow.dateUsed).toISOString(),
        datePaid: null,
        paymentPolicyId: null,
      };

      // "No Financiar"
      if (this.paymentOption === 1) {
        payload.datePaid = new Date(this.datePaid).toISOString();
        payload.paymentPolicyId = null;
      }

      // "Financed" Cost Item
      if (this.paymentOption === 2) {
        payload.paymentPolicyId = this.newRow.paymentPolicyId;
        payload.datePaid = null;
      }

      payload.interestRateId = this.newRow.interestRateId;

      let validate = [
        "activitySliceId",
        "directSliceCostItemId",
        "unitPrice",
        "unitsPerHectare",
        "dateUsed",
      ];
      let fieldToText = {
        activitySliceId: "ID de actividad",
        directSliceCostItemId: "Producto/Servicio",
        unitPrice: "Precio",
        unitsPerHectare: "Unidades x Hectáreas",
        dateUsed: "Fecha de uso",
      };

      let isValid = validateRequestObject(payload, validate);
      if (!isValid.success) {
        let msg = `${fieldToText[isValid?.key]} no puede ser 0 o nulo`;
        let notif = {
          success: false,
          data: {
            type: "alert",
            text: msg,
          },
        };
        this.$emit("setNewNotification", notif);
      } else {
        this.$emit("saveClick", payload);
      }
      this.editMode = false;
    },
    async confirmDeleteClick() {
      let assignedDirectSliceCostItemId =
        this.row.assignedDirectSliceCostItemId;
      this.$emit("confirmDeleteRow", assignedDirectSliceCostItemId);
      this.deleteMode = false;
    },
    editClick() {
      if (this.row.datePaid) {
        this.datePaid = this.row.datePaid;
      } else {
        this.paymentOption = 2;
        this.paymentPolicyId = this.row.paymentPolicyId;
      }

      this.paymentPolicy = this.getPaymentPolicyNameById(
        this.row.paymentPolicyId
      );
      this.selectedPeriod = this.getPeriodIdByName(this.row.periodType);

      this.newRow = {
        ...this.row,
        percentApplicationAsInt: this.row.percentApplication * 100,
      };

      this.computeSubtotal();
      this.editMode = true;
    },
    backClick() {
      this.editMode = false;
      this.deleteMode = false;
    },
    resetNewRow() {
      this.$refs.mainSelect.reset();
      this.$refs.periodSelect.reset();
      this.newRow = {
        id: 1,
        unitPrice: 0,
        family: "",
        units: 1,
        unitMeasure: "kg",
        subTotal: 0,
        dateUsed: "2021-11-05",
        select: "",
        percentApplicationAsInt: 5,
        costInputPeriodId: 1,
      };
    },
  },
  computed: {
    percentAppAsDecimal() {
      return this.newRow.percentApplicationAsInt / 100;
    },
    rowSubtotal() {
      return roundToX(this.row.subTotal, 2).toLocaleString("en-US", {
        timeZone: "America/Argentina/Buenos_Aires",
      });
    },
    displayedDosisString() {
      return this.row.unitsPerHectare + " " + this.row.unitMeasure + "/ha";
    },
    isSandGFamily() {
      return this.row?.family == "Seguimiento y Control";
    },
  },
  async mounted() {
    if (this.hasPaymentPolicy == null) {
      this.hasPaymentPolicy = true;
    }

    if (this.hasFinancing == null) {
      this.hasFinancing = true;
    }

    this.computeSubtotal();
  },
  data() {
    return {
      paymentOption: 1,
      paymentPolicy: null,
      select: null,
      editMode: false,
      deleteMode: false,
      selectedPeriod: null,
      newRow: {
        percentApplicationAsInt: 5,
        costInputPeriodId: 1,
      },
    };
  },
};
</script>
